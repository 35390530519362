
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @import url('https://fonts.googleapis.com/css?family=Montserrat');
  @import url('https://fonts.googleapis.com/css?family=Orbitron');
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
  @import url('https://fonts.googleapis.com/css?family=PT+Mono');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans');
  @import url('https://fonts.googleapis.com/css?family=Inter');

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  h2 {
    color: black !important;
    font-family: 'Inter', sans-serif;
  }


  #data_table * {
    font-size: 0.7rem 

  }

  #loading {
    height: 100%;
    width: 100%;
    background: black;
    color: white;
  }

  #loading:after {
    color: white;
    overflow: hidden;  
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px;
  }

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
      display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }

  .navbar_link {
    font-size: 1.0rem;
    font-weight: 500;
    text-decoration: none;
    padding: 0.25rem 0;
    transition: color 0.1s linear;
    font-family: 'Inter', sans-serif;
    color: white     
    
  }

  #home_text {
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.1s linear;
    font-family: 'Inter', sans-serif;
    color: white;
  }

  #dot_svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }


  .dot-pattern-circle {
    fill: #ffffff; /* Or any other color you desire */
  }

  
  .contact_button {
    background-color: #000000;
    border-radius: 0.25rem;
    display: flex;
    margin: auto;
    text: white;  
    padding: 0.65rem 1.5rem;        
  }

  .contact_button:hover {
    background-color: #333333;
    border-radius: 0.25rem;
    display: flex;
    margin: auto;
    text: white;  
    padding: 0.65rem 1.5rem;
  }

  #login_container {
    background: linear-gradient(to bottom, #E4F7F4, #DCE9F1, #E8E4FA, #F5FAFD);
    border-radius: 5px;
  }

  .main_btn {    
    font-family: 'Inter', sans-serif;
  }

  .changelog { /* Changelog styling consolas*/
    font-family: 'PT Mono', monospace;
    font-size: 0.9rem;    
  }

  
  .gradient-bg {
    /*background: linear-gradient(to bottom, #E4F7F4, #DCE9F1, #E8E4FA, #F5FAFD);*/
    background: linear-gradient(135deg, #f7e4e8 0%, #e4f2f7 25%, #e8f7e4 50%, #f2e4f7 75%, #e4e8f7 100%);
    position: flex;
    height: 100%;
    z-index: 0;
    /*background-image: radial-gradient(black 1px, transparent 1.3px);*/
    /*background-size: 20px 20px; */
  }
  



  @keyframes moveDots {
    to {
        background-position: 40px 0; /* This should be the size of one dot pattern unit */
    }
  }




  video {
    object-fit: fill;
    zoom: 100%;
    -webkit-mask-image: linear-gradient(to top, transparent, black);
    mask-image: linear-gradient(to top, transparent, black);
  }

  .video-container {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  video {
    object-fit: fill;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .video-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 15, 0.55); 
    z-index: 2;
  }

  #image_mask {
    background-color: rgba(0, 0, 15, 0.45); 
  }
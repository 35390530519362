@import url("https://fonts.googleapis.com/css?family=Overpass");
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Questrial");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");
@import url("https://fonts.googleapis.com/css?family=PT+Mono");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=Inter");
.landing {
  width: 100%;
  height: 100vh;
  background: transparent;
}

.landing_video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -11;
}

.landing_container {
  width: 100%;
  height: 100%;
  background: transparent;
  font-family: Inter, sans-serif;
}

.landing_navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: transparent;
  background: black;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  height: 55px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 100;
  padding-left: 20px;
}

.landing_navbar_logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.landing_navbar_logo img {
  height: 27px;
  width: 110px;
  margin-top: 12px;
}

#logo_p {
  font-weight: bolder;
  font-size: 22px;
  font-family: Inter, sans-serif;
  color: #1f1f42;
  margin-top: 10px;
}

.landing_navbar_links {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
  padding-left: 50px;
  padding-top: 15px;
}

.landing_navbar_links a {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  padding: 20px 15px 15px 15px;
  text-decoration: none;
  font-family: Overpass;
  color: white;
}

.landing_navbar_links a:hover {
  color: #e4e4e4;
}

.landing_navbar_user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 10px;
  top: 12px;
}

.landing_body {
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10%;
  font-family: Inter, sans-serif;
}

.landing_body2 {
  width: 100%;
  background: transparent;
  padding-top: 40px;
  padding-left: 10%;
  padding-right: 10%;
  font-family: Inter, sans-serif;
  padding-bottom: 60px;
}

.landing_body_text {
  width: 60%;
}

.landing_title h1 {
  text-align: center;
  font-family: Inter, sans-serif;
  letter-spacing: -1.0px;
  width: 100%;
  font-weight: bold;
}

.landing_body_text h1 {
  font-weight: bolder;
  color: black;
  letter-spacing: -1.0px;
}

.landing_body_text h2 {
  font-weight: bold;
  color: black;
  letter-spacing: -1.0px;
}

.landing_body_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.landing_body_buttons button {
  margin-right: 10px;
  border-radius: 5px;
  width: 120px;
  height: 50px;
  outline: none;
  border: 1px solid black;
  background: black;
  color: white;
}

.landing_body_buttons button:nth-child(2) {
  background: navy;
  color: white;
}

.landing_body_buttons button:nth-child(1):hover {
  background: #282828;
}

.landing_body_text ul {
  list-style: none;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: -40px;
  color: black;
}

.landing_footer {
  width: 100%;
  height: 200px;
  background: black;
  color: white;
}

.landing_about {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 40px;
  font-family: Inter, sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
}

.landing_about_text {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.landing_about_showcase {
  width: 50%;
  background: green;
}
